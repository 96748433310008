<template>
  <div>
    <ManageDownloadFilesStudent />
  </div>
</template>
<script>
import ManageDownloadFilesStudent from "@/components/ManageDownloadFilesStudent/ManageDownloadFilesStudent";
export default {
  components: {
    ManageDownloadFilesStudent,
  },
  created() {},
};
</script>
