<template>
  <v-form>
    <v-card color="#F5F5F5" flat>
      <loading :active.sync="loading"></loading>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" sm="6">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
          >
            ดาวน์โหลดเอกสาร
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="6" sm="6">
          <v-row>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <DialogDownloadFilesStudent @getAll="getAll" />
              <DialogDownloadFilesStudentEdit
                :itemEdit="itemEdit"
                :openDialogEdit="openDialogEdit"
                @getAll="getAll"
                @closeDialogEdit="closeDialogEdit"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pa-3 mt-4" color="#F5F5F5" flat>
      <v-row align="center" justify="center">
        <v-card class="pa-3" width="100%" flat>
          <v-col cols="12" md="12" sm="12">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :headers="headersBrandTh"
                  :items="items"
                  :search="search"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.download`]="{ item }">
                    <v-icon @click="DownloadDocument(item.id)"
                      >mdi-download</v-icon
                    >
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                    <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
                    <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
                  </template>
                  <template
                    v-slot:body="props"
                    v-if="isWindowWidthGreaterThan768"
                  >
                    <draggable
                      :list="props.items"
                      tag="tbody"
                      @change="DragItems(props.items)"
                    >
                      <tr v-for="(item, index) in props.items" :key="index">
                        <td v-if="isWindowWidthGreaterThan768">
                          <v-icon small class="page__grab-icon">
                            mdi-arrow-all
                          </v-icon>
                        </td>
                        <td class="text-start">{{ item.index }}</td>
                        <td class="text-start">{{ item.name_th }}</td>

                        <td class="text-start">
                          <v-row justify="center">
                            <v-icon @click="ViewNews(item)"
                              >mdi-content-copy</v-icon
                            >
                            <v-icon @click="UpdateNews(item)" class="mx-2"
                              >mdi-pencil</v-icon
                            >
                            <v-icon @click="DeleteItem(item)"
                              >mdi-delete</v-icon
                            >
                          </v-row>
                        </td>
                      </tr>
                    </draggable>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-row>
    </v-card>
  </v-form>
</template>
<!-- <template>
  <v-form>
    <v-card class="pa-6" color="#F5F5F5" flat>
      <loading :active.sync="loading"></loading>
      <v-row align="center" justify="center">
        <v-col cols="12" md="10" sm="10">
          <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">
            จัดการคลังความรู้นิเทศศาสตร์
          </span>
        </v-col>
        <v-col cols="12" md="2" sm="2">
          <v-row>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <DialogDownloadFilesStudent @getAll="getAll" />
              <DialogDownloadFilesStudentEdit
                :itemEdit="itemEdit"
                :openDialogEdit="openDialogEdit"
                @getAll="getAll"
                @closeDialogEdit="closeDialogEdit"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-card class="pa-6" width="100%" flat>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :headers="headersBrandTh"
                  :items="items"
                  :search="search"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.download`]="{ item }">
                    <v-icon @click="DownloadDocument(item.id)"
                      >mdi-download</v-icon
                    >
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                    <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
                    <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template> -->
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
import DialogDownloadFilesStudent from "../Dialogs/DialogDownloadFilesStudent.vue";
import DialogDownloadFilesStudentEdit from "../Dialogs/DialogDownloadFilesStudentEdit.vue";
export default {
  components: {
    draggable,
    Loading,
    DialogDownloadFilesStudent,
    DialogDownloadFilesStudentEdit,
  },
  data() {
    return {
      headersBrandTh: [],
      headersBrandTh1: [
        { text: "", value: "", align: "start" },
        { text: "ลำดับ", value: "index", align: "start" },
        {
          text: "ชื่อไฟล์(TH)",
          value: "name_th",
          align: "start",
        },
        // { text: "Download", value: "download", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersBrandTh2: [
        { text: "ลำดับ", value: "index", align: "start" },
        {
          text: "ชื่อไฟล์(TH)",
          value: "name_th",
          align: "start",
        },
        // { text: "Download", value: "download", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],

      dialog: false,
      isEdit: false,
      items: [],
      search: "",
      index: 0,
      user: "",
      loading: false,
      imgUrl: "",

      itemEdit: {},
      openDialogEdit: false,
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAll();
    document.title = "ดาวน์โหลดเอกสาร - GSCM Management System";
  },

  mounted() {
    window.scrollTo(0, 0);

    if (this.isWindowWidthGreaterThan768) {
      this.headersBrandTh = this.headersBrandTh1;
    } else {
      this.headersBrandTh = this.headersBrandTh2;
    }
  },

  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("DragItems", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/downloadFilesStudents/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.getAll();

      this.loading = false;
    },
    DownloadDocument(val) {
      console.log("val", val);
      window
        .open
        // `https://visa-system-service.yuzudigital.com/printPdf/students/01-submit-a-request-for-a-temporary-ED-visa/${val}`
        ();
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },

    async getAll() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/downloadFilesStudents`,
        auth
      );
      console.log("getAll", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].index = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/downloadFilesStudents/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateKnowledgeLibraries");
    },
    async UpdateNews(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/downloadFilesStudents/${val.id}`,
        auth
      );
      this.itemEdit = response.data.data;
      console.log(this.itemEdit);

      this.openDialogEdit = true;
    },
    closeDialogEdit() {
      this.openDialogEdit = false;
    },
    async ViewNews(val) {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/downloadFilesStudents/${val.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      delete response.data.data.id;
      let duplicateData = response.data.data;

      console.log("duplicateData", duplicateData);
      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/downloadFilesStudents`,
        duplicateData,
        auth
      );
      console.log("response1", response1);
      console.log(response1.data.response_status);
      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAll();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      this.loading = false;
    },
  },
};
</script>